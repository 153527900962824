.navBar {
    display: inline
}

.navBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    margin-top: -46px;
    top: 0;
  }
  
  .navBar li {
    float: left;
  }
  
  .navBar li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .navBar li a:hover {
    background-color: #409443;
  }
  
  .navBar .active {
    background-color: #4CAF50;
  }