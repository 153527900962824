.react-cookie-law-dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    background-color: rgb(248, 247, 247);
    padding: 20px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
}

.react-cookie-law-msg {
    display: flex;
    margin: 10px;
}

.react-cookie-law-select-pane {
    display: flex;
    flex-direction: column;
}

.react-cookie-law-option-wrapper {
    margin: 5px;

}

.react-cookie-law-option-checkbox {
    position: absolute;
    left: 0px;
}

.react-cookie-law-option-wrapper {
    margin: 5px;

}

.react-cookie-law-option-checkbox {
    position: absolute;
    left: 0px;
}

.react-cookie-law-policy {
    display: flex;
    margin: 10px;
}

.react-cookie-law-button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

}


.react-cookie-law-accept-all-btn {
    padding: 15px;
    background-color: #4CAF50;
    border: none;
    color: white;
    border-radius: 5px;
    margin: 5px;
    width: 100%;
}


.react-cookie-law-accept-all-btn:hover {
    background-color: #409443;
}

.react-cookie-law-accept-selection-btn {
    padding: 15px;
    background-color: #777777;
    border: none;
    color: white;
    border-radius: 5px;
    margin: 5px;
    width: 100%;
}  

.react-cookie-law-accept-selection-btn:hover {
    background-color: #666666;
}



