#maps {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: unset !important;
}
.gmap_canvas {
    flex: 1;
    height: calc( 100vh - 46px );
}
