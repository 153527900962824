#aboutMe ul {
    list-style-type:none;
    padding: 0
}

#aboutMe li {
    display: table-row;
}

#aboutMe ul li::before {
    content: "✓";
    display: table-cell;
    padding-right: 5px;
}

.aboutMeItem {
    flex: 1;
    min-width: 250px;
    min-height: 250px;
    margin: 20px;
}

#qualifications {

}

#aboutMeImage {
    background-image: url(./images/me.png);
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    margin-bottom: -20px;
    
}