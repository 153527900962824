#therapies .grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#therapies .grid .item{
    display: flex;
    flex-direction: column;
align-items: center;
flex-wrap: wrap;
margin: 20px;

}

#therapies .grid .item .image{
    background-image: url(../images/background_colored.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 200px;
    height: 200px;
    margin: 10px;
    border-radius: 100%;

}