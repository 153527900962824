.background {
    background-image: url(./images/background_colored.svg);
    background-size: cover;
    background-position: top center;
  }

.section {
    padding-top: 46px;
    min-height: calc( 100vh - 46px );
    overflow: auto;
}

.sectionBackground {

}

.viewport {
    margin: 5vh 20vw 5vh 20vw;
    padding: 20px;
    background-color: #FFFFFFAA;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

@media only screen and (max-width: 600px) {
    .viewport {
        margin:10vw;

  }
}